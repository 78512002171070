.list-filter:hover > span > svg > g > path {
    stroke: #fff;
}

.form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.form-switch i {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    background-color: #4f4d4d;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
}

.form-switch i::before {
    content: '';
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #4d5469;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
}

.form-switch i::after {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #191b23;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
    transform: translate3d(16px, 2px, 0);
}

.form-switch input {
    display: none;
}

.form-switch input:checked + i {
    background-color: #149377;
}

.form-switch input:checked + i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
    transform: translate3d(22px, 2px, 0);
}

/* animation */
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Loading Provider */

.loader {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: linear-gradient(#027bcd, #008d91, #009675);
    animation: animate 1.2s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#027bcd, #008d91, #009675);
}

.loader span:nth-child(1) {
    filter: blur(5px);
}

.loader span:nth-child(2) {
    filter: blur(10px);
}

.loader span:nth-child(3) {
    filter: blur(25px);
}

.loader span:nth-child(4) {
    filter: blur(50px);
}

.loader:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    /* background: #f1f1f1; */
    /* border: solid white 10px; */
    border-radius: 50%;
}

/* Text Loading */

h1.loading {
    font-size: 3em;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #fff;
}

span[class^='dot-'] {
    opacity: 0;
}

.dot-one {
    animation: dot-one 2s infinite linear;
}

.dot-two {
    animation: dot-two 2s infinite linear;
}

.dot-three {
    animation: dot-three 2s infinite linear;
}

@keyframes dot-one {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dot-two {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dot-three {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.draggable {
    position: fixed;
    width: 100%;
    height: 40px;
    background: transparent;
    -webkit-user-select: none;
    user-select: none;
    -webkit-app-region: drag;
}

.sidebar-draggable {
    background: transparent;
    -webkit-user-select: none;
    user-select: none;
    -webkit-app-region: drag;
}

.item-symbol-animation {
    /* transform: perspective(45em) rotateY(27deg);
      box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
        rgba(19, 26, 32, 0.08) 0px 35px 75px -35px; */
}

svg.history-btn-action:hover {
    fill: #fff;
}

input:-webkit-autofill ~ svg > g > path {
    stroke: #696969;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    width: 15px;
    height: 15px;
    border-width: thin;
    padding: 0;
    background: transparent;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    width: 15px;
    height: 15px;
    border-width: thin;
    padding: 0;
    background: transparent;
}


input[type="date"] {
    background: transparent;
    color: white;
    color-scheme: dark;
}

input[type="datetime-local"] {
    background: transparent;
    color: white;
    color-scheme: dark;
}
