.dropdown-item {
  border-bottom: 1px solid rgba(131, 138, 161, 0.29);
  justify-content: space-between;
  width: 100%;
  min-width: 350px;
  transform-origin: top right;
  padding: 8px 4px;
}

.animation-symbol-list {
  transition: all 0.3s ease-in-out;
  transform: perspective(10em) rotateY(10deg);
}

.animation-symbol-list:hover {
  transition: all 0.3s ease-in-out;
  transform: perspective(10em) rotateY(10deg);
}

.animation-symbol-list-nomal {
  transition: all 0.3s ease-in-out;
  transform: perspective(10em) rotateY(0deg);
}
