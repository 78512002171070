@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    height: 100%;
}

p {
    color: #fff;
    font-size: 13px;
    margin: 0;
}

select {
    height: 38px;
    border-radius: 8px;
    color: #fff;
    background: transparent;
    border: 1px solid #393939;
    outline: none;
    padding: 0 8px;
    width: -webkit-fill-available;
    margin-top: 10px;
}

button.defult {
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

/* width */
#customScrollTopMenu::-webkit-scrollbar {
    width: 10px;
    height: 1px;
    border-radius: 6px;
}

/* Track */
#customScrollTopMenu::-webkit-scrollbar-track {
    background: #313131;
}

/* Handle */
#customScrollTopMenu::-webkit-scrollbar-thumb {
    background: #169376;
}

/* Handle on hover */
#customScrollTopMenu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

body {
    margin: 0;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: #000000;
    height: 100%;
}

html {
    height: 100%;
}

a {
    text-decoration: none !important;
}

button {
    transition: all 0.2s ease-in;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.flex {
    display: flex;
}

.flex-cols {
    flex-direction: column;
}

.text-xs {
    font-size: 12px;
}

.text-sm {
    font-size: 14px;
}

/* .font-bold {
  font-weight: 400;
} */
.relative {
    position: relative;
}

.items-center {
    align-items: center;
}

.fixed {
    position: fixed;
}

.flex-1 {
    flex: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.mt-4 {
    margin-top: 12px;
}

.justify-between {
    justify-content: space-between;
}

.pointer {
    cursor: pointer;
}

.fixTableHead {
    overflow-y: auto;
    height: 110px;
}

.fixTableHead thead th {
    position: sticky;
    top: 0;
}

.fixTableHead thead tr th {
    background: #000;
}

table {
    width: 100%;
    padding: 12px 0;
    border-collapse: collapse;
}

tr:nth-child(even) {
    background-color: #1e1e1e;
}

table > thead > tr > th {
    color: #fff;
    font-size: 12px;
    text-align: start;
    padding: 8px 16px;
}

table > tbody > tr > th {
    color: #fff;
    font-size: 12px;
    text-align: start;
    padding: 8px 16px;
}

table > tbody > tr > td {
    color: #fff;
    font-size: 12px;
    padding: 8px 16px;
    text-align: start;
}

@keyframes errors {
    0% {
        transform: translateX(-5px);
    }
    25% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0px);
    }
}

.menu-top {
    height: 65px;
    background: #0f1018;
    z-index: 2;
    position: relative;
    border-bottom: 1px solid #232323;
    display: flex;
    align-items: center;
}

.menu-left {
    position: relative;
    background: #0f1018;
    height: calc(100vh - 65px);
    /* top: -65px; */
    z-index: 2;
    min-width: 70px;
    border-right: 1px solid #232323;
    display: flex;
    flex-direction: column;
}

.menu-right {
    position: relative;
    background: #000000;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 1;
    width: 185px;
    border-left: 1px solid #232323;
    padding: 12px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 60px);
}

.center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(56 61 78);
}

.menu-item {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #8e9199;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.menu-item:hover {
    background-color: rgb(55 61 78);
    transition: all 0.3s ease-in-out;
}

.menu-item.active {
    background-color: rgba(0, 0, 0, 0);
}

.menu-item > .text-menu {
    font-size: 12px;
    margin-top: 8px;
}

.menu-item:hover > .text-menu {
    color: rgb(199 200 205);
    transition: all 0.3s ease-in-out;
}

.menu-item > svg {
    fill: #8e9199;
}

.menu-item.active > svg {
    fill: #169376;
}

.menu-item:hover > svg {
    fill: #169376;
    transition: all 0.3s ease-in-out;
}

.menu-item.active > .text-menu {
    color: #169376;
}

.menu-item:hover > .text-menu {
    color: #169376;
}

.explain-port {
    opacity: 1;
    background: #000000;
    border-right: 1px solid #393939;
    transition: all 0.3s;
    width: -moz-fit-content;
    width: 100%;
    height: 100%;
    bottom: 0;
    position: relative;
    max-height: 230px;
}

.explain-port.active {
    transform: translateX(0px);
}

.explain-port.inactive {
    transform: translateX(-100%);
}

#customScrollPortfolo::-webkit-scrollbar {
    width: 2px;
    height: 1px;
    border-radius: 6px;
}

/* Track */
#customScrollPortfolo::-webkit-scrollbar-track {
    background: #313131;
}

/* Handle */
#customScrollPortfolo::-webkit-scrollbar-thumb {
    background: #169376;
}

/* Handle on hover */
#customScrollPortfolo::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#custom-order-scroll::-webkit-scrollbar {
    width: 0px;
    height: 1px;
    border-radius: 6px;
}

/* Track */
#custom-order-scroll::-webkit-scrollbar-track {
    background: #313131;
}

/* Handle */
#custom-order-scroll::-webkit-scrollbar-thumb {
    background: #169376;
}

/* Handle on hover */
#custom-order-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.explain-port > .header {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #393939;
    height: 43px;
}

.header > .text-menu {
    color: rgb(140 143 151);
    font-size: 14px;
}

.header > svg {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.header > svg:hover > path {
    fill: #fff;
    stroke: #fff;
    transition: all 0.3s ease-in-out;
}

.item-portfolio {
    display: flex;
    padding: 12px;
    width: 250px;
    border-bottom: 1px solid #393939;
    cursor: pointer;
}

.item-portfolio:hover {
    background-color: rgb(57 63 81);
}

.item-portfolio > .start {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.item-portfolio > .center {
    display: flex;
    align-items: flex-start;
}

.item-portfolio > .end {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.item-portfolio > .start > .white,
.item-portfolio > .center > .white {
    color: #fff;
    font-size: 14px;
}

.item-portfolio > .start > .gray {
    color: rgb(140 143 151);
    font-size: 10px;
    margin-top: 4px;
}

.item-portfolio > .end > .open {
    color: rgb(187 193 187);
    font-weight: bold;
    font-size: 14px;
}

.item-portfolio > .end > .loss {
    color: rgb(233 110 106);
    font-weight: bold;
    font-size: 14px;
}

svg.profit {
    fill: rgb(98 194 98);
}

svg.loss {
    fill: rgb(233 110 106);
    transform: rotate(180deg);
}

.under {
    margin-top: 4px;
    display: flex;
    align-items: baseline;
}

.open ~ .under > .span {
    color: rgb(98 194 98);
    font-size: 13px;
    margin-right: 4px;
}

.loss ~ .under > .span {
    color: rgb(233 110 106);
    font-size: 13px;
    margin-right: 4px;
}

.open > .under > .span ~ svg {
    margin-left: 4px;
    color: rgb(98 194 98);
}

.custom-scroll-left {
    max-height: calc(100vh - 70px - 80px);
    overflow: auto;
}

#custom-scroll::-webkit-scrollbar-track {
    background-color: #000000;
}

#custom-scroll::-webkit-scrollbar {
    width: 3px;
    background-color: #000000 00;
}

#custom-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(140, 143, 151, 0.128);
    border-radius: 6px;
}

.symbol-flex.active > .div-flex-center > .absolute-items {
    transition: all 0.3s;
    display: block;
    opacity: 1;
    width: fit-content;
    max-width: 100%;
}

.absolute-items {
    opacity: 0;
    transition: all 0.3s;
    display: none;
    opacity: 1;
    width: 0px;
    max-width: 100%;
}

.symbol-flex {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    border-radius: 4px;
    cursor: pointer;
    background: #1e1f30;
    border: 1px solid #2e333d;
    transition: all 0.2s ease-in;
}

.perspactive-symbol-menu {
    transition: all 0.2s ease-in;
    transform: perspective(15em) rotateY(26deg);
}

.perspactive-symbol-menu:hover {
    transition: all 0.2s ease-in;
    /* transform: perspective(25em) rotateY(15deg); */
}

.symbol-flex:hover {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid rgb(54 60 76);
    background: #1e1f30;
}

.symbol-flex.active {
    background: rgb(54 60 76);
    z-index: 0;
    border-bottom: 2px solid #149377;
}

.symbol-flex > .div-flex-center {
    display: flex;
    align-items: center;
    width: max-content;
}

.symbol-flex > .div-flex-center > .symbol-name {
    font-weight: bold;
    color: #fff;
    user-select: none;
    font-size: 12px;
    margin-left: 4px;
}

.btn-add {
    font-size: 30px;
    background: transparent;
    border: 1px solid #363c4c;
    border-radius: 4px;
    margin: 0 8px;
    height: 40px;
    width: 40px;
    color: rgb(140 143 149);
    cursor: pointer;
}

.btn-add:hover {
    color: #fff;
    background-color: #363c4c;
}

.close-symbol {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
}

.overflow-top {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding-right: 18px;
}

img.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.section-profile {
    width: fit-content;
    padding: 0 12px;
    display: flex;
    align-items: center;
}

.section-profile > div {
    display: flex;
    align-items: center;
}

.inves-content {
    margin-left: 12px;
}

.inves-content > .port {
    font-size: 12px;
    color: #b1b1b1;
    text-align: right;
}

.inves-content > .currency-available {
    font-size: 24px;
    text-align: right;
    font-weight: bold;
    cursor: pointer;
}

button.deposit {
    border: 1px solid #169376;
    outline: none;
    height: 40px;
    border-radius: 4px;
    background: transparent;
    color: #169376;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 14px;
}

/* button > span.text-space {
  margin-left: 4px;
} */
button.deposit:hover {
    background-color: #169376;
    color: #fff;
}

svg {
    transition: all 0.3s;
}

button.deposit:hover > span > svg {
    fill: #fff;
}

/* .drop-down-icon > svg {
  transform: rotate(-180deg);
} */
.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    /* border-top: 5px solid #169376; */
    margin-left: 4px;
}

.box-lotsize {
    background: #000000;
    padding: 6px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #393939;
}

.box-lotsize > .text-lebel {
    font-size: 10px;
    color: rgb(133 135 140);
    position: absolute;
}

.box-lotsize > input.lot-size {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    margin-top: 16px;
    font-size: 12px;
}

button.buy {
    width: 100%;
    border: none;
    outline: none;
    height: 48px;
    background: #1f6fde;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
}

button.entry-right {
    width: 100%;
    border: none;
    outline: none;
    height: 48px;
    background: #1f6fde;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    text-align: left;
}

button.entry-right:disabled {
    background: #1e1e1e;
}

button.entry-right > div:first-of-type {
    color: #ffffffcc;
    font-weight: 100;
    margin-bottom: 2px;
    font-size: 12px;
}

button > div {
    margin-left: 12px;
}

button.buy > span > svg {
    transform: rotate(-180deg);
}

button:hover.buy {
    background: #1257b9;
}

button.sell {
    width: 100%;
    border: none;
    outline: none;
    height: 48px;
    background: rgb(203 84 60);
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
}

button.entry-sell-right {
    width: 100%;
    border: none;
    outline: none;
    height: 48px;
    background: rgb(203 84 60);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    text-align: left;
}

button.entry-sell-right:disabled {
    background: #1e1e1e;
}

button.entry-sell-right > div:first-of-type {
    color: #ffffffcc;
    font-weight: 100;
    margin-bottom: 2px;
    font-size: 12px;
}

button:hover.sell {
    background: rgb(203 67 39);
}

.text-profit-right {
    font-size: 32px;
    font-weight: 300;
    margin-top: 12px;
    text-align: center;
}

.text-profit-right > span {
    font-size: 20px;
    font-weight: 300;
}

div.text-smalll {
    color: #c6c6c6;
    text-align: center;
    font-size: 12px;
}

.text-profit-dollar {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 12px;
}

.dropdown-symbol {
    position: fixed;
    background: #fff0;
    z-index: 4;
    max-height: 600px;
}

/* .dropdown-symbol::before {
  content: '\A';
  position: fixed;
  background: rgb(11 10 10 / 59%);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
} */

.bg-dropdown {
    display: flex;
    background: #0f1018;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 0px solid #80808038;
    max-height: 600px;
    overflow: hidden;
    border-bottom: 1px solid #393939;
    border-left: 1px solid #393939;
    border-right: 1px solid #393939;
}

.bg-dropdown > div {
    padding: 10px;
    height: 600px;
    overflow: hidden;
    min-width: fit-content;
}

.bg-dropdown > div ~ div {
    width: 500px;
    border-left: 1px solid #373c4847;
}

/* width */
#overflow-auto::-webkit-scrollbar {
    width: 2px;
}

/* Track */
#overflow-auto::-webkit-scrollbar-track {
    background: #202020;
}

/* Handle */
#overflow-auto::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
#overflow-auto::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.bg-dropdown > div ~ div > div.overflow-auto {
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 100%; */
}

.dropdow-item {
    color: #fff;
    font-weight: bold;
    padding: 12px 12px;
    cursor: pointer;
    font-size: 14px;
    /* width: 200px; */
}

.animation-down {
    transition: all 0.5s ease-in-out;
    transform-origin: top;
    animation: TestAnimation 0.5s ease-in-out;
}

.dropdow-item.animation-down {
    transition: all 0.5s ease-in-out;
    transform-origin: left top;
}

.dropdow-item:hover {
    background-color: rgb(26 29 33);
    transition: all 0.3s ease-in-out;
    border-radius: 6px;
}

.dropdow-item.active {
    background-color: rgb(26 29 33);
    border-radius: 6px;
}

/* login */

#loginForm {
    background: #1e1e1e;
    overflow: auto;
}

.login-form {
    height: 100%;
    display: block;
    width: 100%;
}

.login-form > div {
    margin: auto;
    display: flex;
    flex-direction: column;
    min-width: 250px;
}

.login-form > div > input {
    border: none;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    padding: 0 12px;
    outline: none;
    margin-bottom: 12px;
}

.lotsite-panel {
    display: flex;
    position: absolute;
    right: 0px;
    flex-direction: column;
    top: 0;
    height: 100%;
}

.lotsit-button-up {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    height: 100%;
    width: 30px;
    border-top-right-radius: 4px;
}

.lotsit-button-up:hover {
    background: #1f6fde;
    border: none;
    color: #fff;
    cursor: pointer;
    height: 100%;
    border-top-right-radius: 4px;
}

.lotsit-button-down {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    height: 100%;
    width: 30px;
    border-bottom-right-radius: 4px;
}

.lotsit-button-down:hover {
    background: rgb(203 67 39);
    border: none;
    color: #fff;
    cursor: pointer;
    height: 100%;
    border-bottom-right-radius: 4px;
}

.trade-dialog {
    position: relative;
    height: 100vh;
    width: 400px;
    background: #0d0e15;
    z-index: 1;
    border-right: 1px solid #232323;
}

.trade-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 12px; */
    border-bottom: 1px solid #232323;
}

.trade-dialog-header > div {
    padding: 12px;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.input-left-lotsize {
    background: transparent;
    outline: none;
    border-left: none;
    border-right: none;
    width: 100%;
    color: #fff;
    text-align: center;
    border: none;
    font-size: 14px;
}

.input-left-lotsize.errors {
    animation: errors 0.1s ease-in-out;
    background: #000;
    outline: none;
    border-left: none;
    border-right: none;
    height: 40px;
    width: 100%;
    color: #c30c0c;
    text-align: center;
}

#close.box-input-lotsize {
    display: flex;
    align-items: center;
    margin: 0px;
    margin-top: 12px;
    border: 1px solid #393939;
    border-radius: 8px;
}

.box-input-lotsize {
    display: flex;
    align-items: center;
    border: 1px solid #393939;
}

.box-input-lotsize > div {
    height: 100%;
}

.box-input-lotsize > div > button.dec {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    /* border-left: 1px solid #393939; */
    width: 30px;
}

#close > div > button.dec {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: rgba(203, 66, 39, 0);
    color: #fff;
    width: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

#close > div > button.dec:hover {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: rgb(203 67 39);
    color: #fff;
    width: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

#close > div > button.inc {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    width: 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

#close > div > button.inc:hover {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: #1f6fde;
    color: #fff;
    width: 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.box-input-lotsize > div > button.dec:hover {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: rgb(203 67 39);
    color: #fff;
    width: 30px;
}

.box-input-lotsize > div > button.inc {
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    width: 30px;
    height: 44px;
}

.box-input-lotsize > div > button.inc:hover {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: #1f6fde;
    color: #fff;
    width: 30px;
}

input[type='checkbox'].custom-radio {
    appearance: none;
    background-color: #000000;
    margin: 0;
    border: 1px solid #4a4a4a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    margin-right: 12px;
    cursor: pointer;
}

input[type='checkbox']:checked.custom-radio::after {
    content: '\A';
    appearance: none;
    background-color: #149377;
    margin: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000000;
}

.at-market {
    display: flex;
    align-items: center;
    margin-top: 26px;
    background: #1e1e1e;
}

.at-market > .bid {
    color: #1f6fde;
    width: 100%;
    background-color: #1e1e1e;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bid ~ div {
    height: 20px;
    width: 1px;
    background: rgb(57 57 57);
}

.at-market > .ask {
    color: #bd5b44;
    width: 100%;
    background-color: #1e1e1e;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-buy-sell > button.sell {
    background-color: #bd5b44;
    height: 40px;
    width: 100%;
    border-radius: 0;
    font-size: 14px;
    font-weight: 500;
}

.button-buy-sell > button.buy {
    background-color: #1f6fde;
    height: 40px;
    width: 100%;
    border-radius: 0;
    font-size: 14px;
    font-weight: 500;
}

.position-dialog {
    position: relative;
    width: 400px;
    height: 100%;
    background: #0d0e15;
    z-index: 1;
    border-right: 1px solid #393939;
    /* border-left: 1px solid #393939; */
}

.position-dialog > .header > .flex-header {
    padding: 12px;
    background-color: #141621;
}

.position-dialog > .header > .flex-header > P:first-of-type {
    font-weight: bold;
}

.position-dialog > .header {
    background-color: #1e1e1e;
    border-bottom: 1px solid #232323;
}

.history-dialog {
    position: relative;
    width: 400px;
    height: 100vh;
    background: #0d0e15;
    z-index: 1;
    border-right: 1px solid #232323;
    /* border-left: 1px solid #393939; */
}

.history-dialog > .header > .flex-header {
    padding: 12px;
    background-color: #1a1d24;
}

.history-dialog > .header > .flex-header > P {
    width: 20%;
}

.history-dialog > .header > .flex-header > P:first-of-type {
    font-weight: bold;
}

.history-dialog > .header {
    background-color: #1e1e1e;
    border-bottom: 1px solid #393939;
}

.table-order-scroll.history > .table-body {
    border-bottom: 0px solid #393939;
    transition: all 0.3s;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.order__p {
    display: flex;
    flex-direction: column;
}

.order__p > .header {
    position: sticky;
    top: 0;
    z-index: 99;
}

.order__p > .header > .flex-header {
    padding: 12px;
    background-color: #1a1d24;
}

.order__p > .header > .flex-header > p:first-of-type {
    font-weight: bold;
}

.action-order > .outline-custom {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 22px;
    height: 20px;
}

.table-body {
    border-bottom: 1px solid #393939;
    transition: all 0.3s;
    /* height: 84px; */
    position: relative;
    z-index: 2;
    background-color: #0d0e15;
}

.table-body:hover {
    border-bottom: 1px solid #393939;
    transition: all 0.3s;
    /* height: 62px; */
    position: relative;
    z-index: 2;
    background-color: rgb(33, 37, 48);
}

/* calc(100% - var(--preview-position) * 100%) */
.table-body > .flex-body P {
    width: 20%;
}

.table-body.table-body-open {
    height: 200px;
}

.table-body.table-order-open {
    height: 174px;
}

.table-order-scroll {
    position: relative;
    display: block;
    overflow: auto;
    height: calc(100vh - 356px);
}

.table-position-scroll {
    position: relative;
    display: block;
    overflow: auto;
    height: calc(100vh - 108px);
}

.table-order-scroll-order {
    position: relative;
    display: block;
    overflow: auto;
    height: calc(100vh - 250px);
}

.position-open {
    transition: all 0.3s ease;
    animation: positionAnimation 1000ms;
    width: 320px;
}

.position-hide {
    transition: all 0.3s ease;
    width: 0px;
    opacity: 0;
}

.trade-open {
    transition: all 0.1s ease;
    animation: tradeAnimation 300ms;
    width: 320px;
    opacity: 1;
}

.trade-hide {
    transition: all 0.1s ease;
    width: 0px;
    opacity: 0;
}

@keyframes positionAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes tradeAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.relate-180 {
    transition: all 0.3s;
    transform: rotate(180deg);
}

.relate-0 {
    transition: all 0.3s;
    transform: rotate(0deg);
}

.text-white {
    color: #fff;
}

.explan-setting.close {
    margin-top: 0px;
    transition: all 0.2s;
    opacity: 0;
    transform-origin: top;
}

.explan-setting.open {
    /* margin-top: 16px; */
    transition: all 200ms;
    position: relative;
    opacity: 1;
    z-index: 1;
}

.explan-setting > .setting > .progress {
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
}

.explan-setting > .setting > .tp,
.sl {
    width: 20%;
    font-size: 12px;
}

.position-detail-setting {
    font-size: 14px;
    margin-top: 18px;
}

.position-detail-swap {
    font-size: 14px;
    margin-top: 10px;
}

.position-detail-setting > .open-time {
    color: #999999;
}

.position-detail-setting > .time-value {
    color: #fff;
}

.position-detail-swap > .open-time {
    color: #999999;
}

.position-detail-swap > .time-value {
    color: #fff;
}

.bg-progress-sltp {
    background: #393939;
    width: 90%;
    height: 4px;
    border-radius: 6px;
}

.progress-sl-tp {
    width: 90%;
    height: 3px;
    position: absolute;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    right: 20px;
    transition: all 0.4s ease-in-out;
}

.progress-sl-tp::before {
    content: '\A';
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 0;
    position: absolute;
    transition: all 0.4s ease-in-out;
}

.progress-sl-tp::after {
    content: '\A';
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    right: var(--sl-tp-position);
    position: absolute;
    transition: all 0.4s ease-in-out;
}

.open-components {
    position: relative;
    height: 100vh;
    transform: translateX(0%);
    /* transition: all 0.1s; */
    width: 370px;
}

.hide-components {
    position: absolute;
    height: 100vh;
    top: 66px;
    left: 0;
    margin-left: 81px;
    transform: translateX(-101%);
    transition: all 0.1s;
}

@keyframes rotateX {
    from {
        transform: rotateX(280deg);
    }
    to {
        transform: rotateX(360deg);
    }
}

@keyframes TestAnimation {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

.search-input {
    width: -webkit-fill-available;
    height: 40px;
    background: #12131654;
    outline: none;
    border: 1px solid #3a3b3f;
    color: #fff;
    padding: 0 12px 0px 30px;
    font-size: 16px;
}

.absolute-svg-search {
    position: absolute;
    transform: translate(12px, 16px);
}

.dropdown-menu {
    top: 45px;
    right: -4px;
    background: #191b23;
    padding: 4px 12px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 160px;
    border-radius: 6px;
}

.dropdown-menu > .item {
    padding: 4px 0;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.select-open-type {
    background: transparent;
    border: none;
    height: 100%;
    width: 100%;
    color: #fff;
    outline: none;
    margin: 0;
    font-size: 14px;
    cursor: pointer;
}

.button-outline-entry {
    color: #fff;
    width: 100%;
    background-color: #1f6fde;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.button-outline-entry:disabled {
    color: gray;
    width: 100%;
    background-color: #1e1e1e;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: not-allowed;
}

.select-expire {
    background: transparent;
    outline: none;
    border: 1px solid #393939;
    height: 40px;
    color: #fff;
    text-align: left;
}

.select-expire > select {
    height: 100%;
    background: transparent;
    color: #fff;
    border: none;
    width: 100%;
    outline: none;
    margin: 0;
    font-size: 14px;
}

.lotsize-manual {
    width: 100%;
    justify-content: space-around;
    height: 40px;
    margin-top: 12px;
}

.lotsize-manual > .step_01 {
    cursor: pointer;
    font-size: 12px;
}

.lotsize-manual > .step_01:hover {
    color: #149377;
    transform: scale(1.5);
    transition: all 0.3s;
}

.lotsize-manual > .step__01 {
    cursor: pointer;
    font-size: 14px;
}

.lotsize-manual > .step__01:hover {
    color: #149377;
    transform: scale(1.2);
    transition: all 0.3s;
}

.lotsize-manual > div > input {
    width: 40px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #169376;
    font-size: 16px;
    text-align: center;
}

button#ContextComponent {
    background: transparent;
    height: 100%;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
}

.border-ContextComponent {
    transition: all 0.3s;
    padding: 8px;
    border-radius: 4px;
}

.border-ContextComponent:hover {
    background: #282828;
    padding: 8px;
    border-radius: 4px;
}

.drawer-header {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drawer-header > .title {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.drawer-header > div > svg {
    cursor: pointer;
}

.drawer-header > div > svg.add {
    margin-right: 12px;
}

.drawer-body {
    overflow: auto;
}

.drawer-body > .item {
    padding: 16px 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.4s;
    height: 74px;
}

.drawer-body > .item:hover {
    background: rgb(20 22 26);
}

.drawer-body > .item > .info > .account_info {
    margin-left: 12px;
}

.drawer-body > .item > .info {
    display: flex;
    align-items: center;
}

.account_info > .account_type {
    font-size: 14px;
}

.account_type.active {
    font-weight: bold;
}

.drawer-body > .item.active {
    background: rgb(26 29 33);
    color: #fff !important;
}

.account_info > .current_balance {
    margin-top: 2px;
    font-weight: 500;
    font-size: 14px;
    font-family: sans-serif;
}

.history-dialog > .time {
    display: flex;
}

.history-dialog > .time > .from > input {
    background: #1b1d24c2;
    color: #fff;
    border: none;
    outline: none;
    height: 40px;
    padding: 0 9px;
}

.history-dialog > .time > .to > input {
    background: #1b1d24c2;
    color: #fff;
    border: none;
    outline: none;
    height: 40px;
    padding: 0 9px;
}

input.input-date-time {
    height: 40px;
    background: transparent;
    color: #fff;
    padding: 0 12px;
    width: -webkit-fill-available;
    border: 1px solid #393939;
    outline: none;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
}

.button-outline-logout {
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.button-outline-logout > svg:hover {
    fill: #fff !important;
}

.pointer {
    cursor: pointer;
}

.h-full {
    height: 100%;
}

.center-position-ele {
    /*width: 60%;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right-position-ele {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.backdrop {
    background: #00000094;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
}

.edit-position > label {
    color: #fff;
}

.edit_position_T {
    width: 100%;
}

.edit-position > input.symbol-info {
    height: 40px;
    border-radius: 8px;
    color: #a2a2a2;
    background: transparent;
    border: 1px solid #393939;
    outline: none;
    padding: 0 8px;
    width: -webkit-fill-available;
    margin-top: 4px;
}

.edit-position > select {
    height: 45px;
    border-radius: 8px;
    color: #a2a2a2;
    background: #272727;
    border: 1px solid #393939;
    outline: none;
    padding: 0 8px;
    width: -webkit-fill-available;
    margin-top: 10px;
}

.edit_position_T > .box-input-lotsize {
    display: flex;
    align-items: center;
    margin: 4px 20px;
    border: 1px solid #393939;
    border-radius: 8px;
}

.edit_position_T > .box-input-lotsize > div {
    height: 100%;
}

.edit_position_T > .box-input-lotsize > div > button.dec {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    width: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.edit_position_T > .box-input-lotsize > div > button.dec:hover {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: rgb(203 67 39);
    color: #fff;
    width: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.edit_position_T > .box-input-lotsize > div > button.inc {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    width: 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.edit_position_T > .box-input-lotsize > div > button.inc:hover {
    border: none;
    height: 44px;
    cursor: pointer;
    background-color: #1f6fde;
    color: #fff;
    width: 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.edit_position_T.input-left-lotsize {
    background: transparent;
    outline: none;
    border-left: none;
    border-right: none;
    height: 40px;
    width: 100%;
    color: #fff;
    text-align: center;
    border: none;
}

.exit_price_bid_ask {
    text-align: center;
    display: flex;
    justify-content: center;
}

.exit_price_bid_ask > .ask {
    font-size: 30px;
    color: #fff;
    flex: 1;
}

.exit_price_bid_ask > .bid {
    font-size: 30px;
    color: #fff;
    flex: 1;
}

.exit_price_bid_ask > .center {
    font-size: 30px;
    color: #fff;
    margin: 0 12px;
    flex: 1;
    max-width: 10px;
}

.exit_price_bid_ask > .bid-edit {
    font-size: 30px;
    color: #fff;
    flex: 1;
}

.button_modify {
    border: 1px solid rgb(57, 57, 57);
    border-radius: 8px;
    height: 40px;
    background: #ff7b06;
    color: #fff;
    padding: 0 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
}

.button_modify:disabled {
    background: transparent;
    color: #393939;
    cursor: not-allowed;
}

.modify_text {
    color: #fff;
    font-size: 12px;
    padding: 0 20px 20px;
    text-align: center;
}

.loader-css {
    width: 15px;
    height: 15px;
    border: 2px dotted #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}

.slide {
    transform: translateY(10px);
}

@keyframes slide {
    0% {
        transform-origin: top;
        transform: perspective(56em) rotateX(320deg);
        opacity: 0.3;
    }
    20% {
        transform-origin: top;
        transform: perspective(56em) rotateX(340deg);
        opacity: 0.5;
    }
    40% {
        transform-origin: top;
        transform: perspective(56em) rotateX(360deg);
        opacity: 0.5;
    }
    60% {
        transform-origin: top;
        transform: perspective(56em) rotateX(370deg);
        opacity: 0.7;
    }
    80% {
        transform-origin: top;
        transform: perspective(56em) rotateX(390deg);
        opacity: 0.9;
    }
    100% {
        transform-origin: top;
        transform: perspective(65em) rotateX(360deg);
        opacity: 1;
    }
}

#items_transition {
    animation: slide;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating-infinit {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
